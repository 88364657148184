









import vue from 'vue';
import Loader from '@/views/Loader.vue';

export default vue.extend({
    name: 'App',
    components: {Loader},
    methods: {
        stylesLoaded() {
            this.styled = true;
        },
    },
    data() {
        return {
            styled: false,
        };
    },
    created() {
        document.addEventListener('styleload', this.stylesLoaded);
    },
    beforeDestroy() {
        document.removeEventListener('styleload', this.stylesLoaded);
    },
});
