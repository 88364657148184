









import vue from 'vue';
import StatModel from '@/models/stat.model';
import QuestionModel from '@/models/question.model';
import ActionModel from '@/models/action.model';

export default vue.extend({
    name: 'AverageChart',
    props: {
        statisticData: [Object, StatModel],
    },
    computed: {
        statistic() {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            // eslint-disable-next-line no-restricted-globals
            if (isNaN(Number(this.statisticData.statistics.average))) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                const currency = this.statisticData.statistics.average.charAt(0);
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                const value = Number(this.statisticData.statistics.average.replace(currency, '')).toFixed(1);
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                return `${currency} ${value}`;
            }
            return Number(this.statisticData.statistics.average).toFixed(1);
        },
    },
});
