
import Stats from '@/views/Stats.vue';
import StatModel from '@/models/stat.model';
import ScriptModel from '@/models/script.model';
import WindowMessageModel from '@/models/functional/window-message.model';
import Chart from 'chart.js';

interface Stylesheet {
    [key: string]: string;
}

interface LiveStatisticsState {
    activeIdx: number;
    questionsOnly: boolean;
    stats: Record<string, StatModel>;
    script: ScriptModel;
}

export default Stats.extend({
    data() {
        return {
            activeIdx: 0,
            questionsOnly: true as boolean,
            stats: {} as Record<string, StatModel>,
            script: null as null | ScriptModel,
        };
    },
    methods: {
        getScriptOutline() {
            /* method disabled */
        },
        getStats() {
            /* method disabled */
        },
        updateStyle(payload: Stylesheet): void {
            const {style} = document.documentElement;

            // Update the CSS variables on the root node.
            Object.keys(payload).forEach((property: string) => {
                style.setProperty(
                    `--${property.replaceAll('_', '-')}`,
                    payload[property],
                );
            });

            // Ensure the graphs are updated.
            this.updateGraphStyle(payload);
        },
        updateGraphStyle(payload: Stylesheet): void {
            if ('body_color' in payload) {
                Chart.defaults.global.defaultFontColor = payload.body_color;
            }

            this.setState({
                stats: Object.keys(this.stats).reduce(
                    (carry: object, uuid: string) => ({
                        ...carry,
                        [uuid]: {
                            ...this.stats[uuid],
                            style: payload,
                        },
                    }),
                    {},
                ),
            } as unknown as LiveStatisticsState);
        },
        setState(payload: LiveStatisticsState): void {
            Object.assign(this, payload);
        },
        messageHandler(event: MessageEvent) {
            const message = event.data as WindowMessageModel;
            const handlerMap = {
                updateStyle: this.updateStyle,
                setState: this.setState,
            } as Record<string, (payload: object) => void>;
            const handler = handlerMap[message.type] ?? null;

            if (handler !== null) {
                handler(message.content);
            }
        },
    },
    created() {
        window.addEventListener('message', this.messageHandler);
    },
    destroyed() {
        window.removeEventListener('message', this.messageHandler);
    },
});
